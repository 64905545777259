
// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');
@import url('https://cdn.datatables.net/1.10.15/css/dataTables.bootstrap.min.css');


// Variables
@import "variables";
$primary: #FC5C5C;
$primary-dark: #ca4a4a;
$navbar: #3B3B3B;
$icon-font-path: "../fonts/";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";

body {
    font-family: 'Roboto', sans-serif;
    background: #f9f9f9;
}
#listview {
    .item {
        display: block;
        background: rgba(255,255,255,0.06);
        padding: 12px 20px 12px 10px;
        margin-bottom: 20px;
        border-radius: 7px;
        color: #ffffff;
        .bulb{
            font-size: 50px;
            position: relative;
            line-height: 25px;
            &.green,
            &.confirmed {
                color: #56C152;
            }
            &.yellow,
            &.proposal {
                color: #C1B452;
            }
            &.red,
            &.canceled {
                color: #C15252;
            }
        }
        .title {
            font-weight: bold;
        }
    }
}
.body {
    color: #222222;
    padding-top: 30px;
    h1 {
        font-weight: 100;
        font-size: 42px;
        color: #aaaaaa;
    }
    .customerform {
        .radio {
            clear: both;
            height: 58px;
            &>label {
                display: block;
                font-weight: bold;
                padding: 0;
            }
            div {
                width: 33%;
                float: left;
                input {
                    margin: 0;
                }
            }
        }
    }
    .registerform {
        .form-group {
            clear: both;
            padding: 8px 0;
            &.radio {
                &>label {
                    font-weight: bold;
                }
                div {
                    width: 50%;
                    float: right;
                    label {
                        display: inline;
                        text-align: left;
                        float: none;
                    }
                    input {
                        display: inline;
                        float: none;
                        text-align: left;
                        width: auto;
                        margin: 3px 20px 0 0 ;
                    }
                }
            }
        }
        label {
            float: left;
            display: block;
            width: 45%;
            text-align: right;
            padding-top: 4px;
        }
        input, button, select{
            display: block;
            float: right;
            width: 50%;
            &[type=radio]
            {
                display: inline;
            }
        }
    }
    .dateplanner {
        list-style: none;
        margin: 0;
        padding: 0;
        input {
            display: block;
            width: 50px;
            float: left;
        }
        li {
            &.disabled {
                color: #cccccc;
            }
            label {
                display: block;
                padding: 10px;
                margin: 0;
            }
            background: #ececec;
            &:nth-child(2n+1){
                background: #f5f5f5;
            }
        }
    }
    table {
        width: 100%;
        thead {
            th {
                border-bottom: 1px solid #dddddd;
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #eeeeee;
            }
            td {
                padding: 6px 2px;
            }
        }

    }
    .usage .fa {
        font-size: 20px;
    }
    button {
        &.usage {
            background: none;
            padding: 0;
            font-size: 20px;
            border: none;
        }
    }
    .tag {
        background: #3097D1;
        color: #ffffff;
        border-radius: 3px;
        font-weight: bold;
        padding: 3px 5px;
        text-decoration: none;
        box-shadow: 0px 1px #156b9b;
        &:hover {
            background: #2789c0;
        }
    }
    .bulb{
        &::after {
            content: "\00a0";
            background: red;
            height: 13px;
            display: inline-block;
            width: 13px;
            margin-right: 10px;
            position: relative;
            top: 3px;
            border-radius: 9px;
        }
        &.green::after,
        &.confirmed::after {
            background: #56C152;
        }
        &.proposal::after {
            background: #e1af58;
        }
        &.red::after,
        &.cacelled::after {
            background: #C15252;
        }
    }

}

nav.sidebar {
    background:$navbar;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    color: #ffffff;
    width: 80px;
    padding: 0;
    .buttontitle {
        display: none;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: block;
            &.logo {
                padding: 20px 10px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            a {
                display: block;
                padding: 8px 10px;
                color: #ffffff;
                text-decoration: none;
                &:hover {
                    background: $primary-dark;
                }
                &.active {
                    background: $primary;
                }
                .fa {
                    display: block;
                    float: left;
                    text-align: center;
                    font-size: 22px;
                    width: 60px;
                }

            }
        }
    }
    .user {
        text-align: center;
        border-top: 1px solid #474747;
        margin-top: 30px;
        padding: 13px;
        .logout {
            border-radius: 5px;
            background: $primary-dark;
            padding: 2px 4px;
            color: #ffffff;
            &:hover {
                background: $primary;
            }
        }
    }
    @media (min-width: $screen-md-min) {
        width: 220px;
        ul {
            li {
                .fa {
                    display: block;
                    float: left;
                    text-align: center;
                    font-size: 22px;
                    width: 40px;
                }
                .buttontitle {
                    display: block;
                }
            }
        }
    }



}
.bikeid {
    background: #eee;
    color: #101010;
    padding: 20px;
    border-radius: 20px;
    font-weight: normal;
    .code {
        font-size: 44px;
        font-family: Menlo, Monaco, Consolas, monospace;
    }
    text-align: center;
}
.dashboard {
    margin: 0 auto;
    padding: 20px;
    width: 500px;
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 20px -10px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 5px 20px -10px rgba(0,0,0,0.25);
    box-shadow: 0px 5px 20px -10px rgba(0,0,0,0.25);


    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
        width: 800px;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
        width: 700px;
    }

    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: $screen-lg-min) {
        width: 1050px;
    }

    /* Xtra Large devices (large desktops, 1200px and up) */
    @media (min-width:1400px) {
        width: 1200px;
    }
    .servicecards {
        text-align: center;
        background: #eeeeee;
        border: 1px solid #ddd;
        margin-bottom: 20px;
        padding: 20px;
        border-radius: 3px;
        h3 {
            font-size: 26px;
        }

        .period {
            font-size: 20px;
        }
        .price_original {
            font-size: 15px;
            font-weight: 400;
            display: block;
            color: #111;
        }
        .appointment {
            padding-top: 20px;
        }
        .appointmentblock {
            display: block;
            color: #ffffff;
            font-size: 30px;
            margin: 20px;
            padding: 10px;
            font-weight: 300;
            border-radius: 5px;
            &.confirmed {
                background: #56C152;
            }
            &.proposal {
                background: #C1B452;
            }
            &.canceled {
                background: #C15252;
            }
            .date {
                display: block;
                font-weight: bold;
                font-size: 15px;

            }

        }
        .price {
            font-size: 32px;
            font-weight: 300;
            display: block;
        }
    }
    .action {
        color: $primary;
        font-weight: bold;
        /*
        background: $primary;
        color: #ffffff;
        font-size: 13px;
        border-radius: 3px;
        padding: 3px;
        font-weight: bold;
        */
    }
    .title-icon {
        font-size: 70%;
    }
    table.overview {
        tr {
            border: none;
            td {
                &:first-child {
                    font-size: 12px;
                    font-weight: normal;
                    color: #777777;
                    width: 200px;
                }
                vertical-align: top;
                padding: 4px 0;
                p:last-child {
                    margin: 0;
                }
            }
        }
    }
    ul.table {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            margin: 0;
            // background: #efefef;
            border-bottom: 1px solid #eeeeee;
            &:first-child{
                border-top: 1px solid #eeeeee;
            }
            &:nth-child(2n+1){
                // background: #f9f9f9;
            }
            a {
                padding: 7px;
                display: block;
                color: #121212;
                text-decoration: none;
                &:hover {
                    background: #f9f9f9;
                }
            }
        }
    }
    .description-box {
        background: #e7f7ff;
        border-radius: 4px;
        padding: 13px;
        font-weight: 400;
        p:last-child {
            margin-bottom: 0;
        }
        &.h1-padding {
            margin-top: 22px;
        }
        .title {
            font-weight: bold;
        }
    }
    .statusbuttons {
        padding-top: 100px;
        .btn {
            display: block;
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
.navigator {
    background: #2E2E2E;
    position: fixed;
    top: 0;
    left: 70px;
    bottom: 0;
    overflow-y: scroll;
    #search {
        padding: 12px 0;
        input {
            display: block;
            border-width: 0;
            padding: 9px 15px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 7px;
            background: rgba(0,0,0,0.25);
            color: #999999;
            font-weight: bold;
        }
    }
}

body#site {
    color: #393b39;
    section {
        padding: 60px 0;
    }
    section#introduction {
        background: #222222;
        color: #ffffff;
        text-align: center;
        h1 {
            font-size: 32px;
            line-height: 40px;
            font-weight: bold;
            .green {
                color: #a3d258;
            }
            .subline {
                display: block;
                font-weight: 100;

            }
        }
    }
    section#features {
        text-align: center;
        h2 {
            font-weight: 700;
            font-size: 32px;
            padding:0;
            margin: 15px 0 0 0;
            color: #393b39;
        }
        .feature {
            padding: 20px 15px;
            .fa {
                font-size: 55px;
                color: $primary;

                //
            }
            h3 {
                //
                font-weight: 400;
                margin: 15px 0 15px 0;
            }
            p {
                font-size: 16px;
                line-height: 32px;
                font-weight: 300;
            }
        }
    }
    section#mails {
        background: #efefef;
        h2 {
            font-size: 22px;
            font-weight: bold;
        }
        p {
            font-size: 16px;
            font-weight: 400;
        }
    }
    section#contact {
        background: $primary;
        color: #ffffff;
        textarea#questions {
            height: 143px;
        }
        .btn.btn-success {
            background: #77f177;
            color: #3c9d3c;
            border-color: #52ca52;
            font-weight: 700;
        }
    }
}
#splash {
    height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    .welcome {
        background: URL('/images/background.png') no-repeat center center;
        background-size: cover;
        height: 50%;
        color: #ffffff;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        p {
            font-size: 16px;
            line-height: 32px;
        }
    }
    .buttons {
        height: 50%;
        .login {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            &.klanten {
                background-image: URL('/images/klanten.png');
                a {
                    color: $primary;
                    border-color: $primary;
                    &:hover {
                        background: $primary;
                        color: #ffffff;
                    }
                }
            }
            &.bedrijven {
                background-image: URL('/images/bedrijven.png');
            }

            a {
                padding:  10px 20px;
                border: 2px solid #ffffff;
                color: #ffffff;
                border-radius: 4px;
                text-decoration: none;
                font-weight: 700;
                text-transform: uppercase;

                -webkit-transition-duration: 0.3s; /* Safari */
                transition-duration: 0.3s;

                &:hover {
                    border-color: $primary;
                    color: $primary;
                }
            }
        }
    }
}
// .dataTables_paginate {
//     &>a,
//     &>span {
//     }
//     .paginate_button  {
//         padding: 5px 9px;
//         display: inline-block;
//         border: 1px solid red;
//     }
// }
//@import "light";
